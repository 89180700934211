#choices-manager {
    margin: 20px 0px;
    padding: 20px;
    background-color: #ffffffaa;
    border-radius: 20px;
    font-family: Arial, sans-serif;
}

table {
    width: 100%;
    border-collapse: collapse;
}

thead {
    background-color: #f5f5f5;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

input {
    width: 100%;
    padding: 4px;
    box-sizing: border-box;
}

button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
}

button:hover {
    background-color: #0056b3;
}