.highlight-icon-button {
    display: inline-block;
    animation: pulse-glow 2s infinite ease-in-out;
    /* border-radius: 50px; */
}

/* Keyframe animation for the glowing effect */
@keyframes pulse-glow {
    0% {
        box-shadow: 0 0 10px 2px rgba(136, 132, 216, 0.6), 0 0 20px 5px rgba(136, 132, 216, 0.4);
    }

    50% {
        box-shadow: 0 0 15px 4px rgba(136, 132, 216, 0.8), 0 0 30px 8px rgba(136, 132, 216, 0.6);
    }

    100% {
        box-shadow: 0 0 10px 2px rgba(136, 132, 216, 0.6), 0 0 20px 5px rgba(136, 132, 216, 0.4);
    }
}