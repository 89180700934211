.App {
  text-align: center;
}

.katex-html {
  display: inline-flex;
  direction: ltr;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  row-gap: 10px;
  justify-items: center;
  align-items: center;
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.char-count {
  font-size: 0.8rem;
  margin-left: 5px;
}

h4 {
  font-weight: 400;
  margin-top: -10px;
}

div pre {
  padding: 1em !important;
}

pre {
  background: #282C34;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 1.6em;
  border-radius: 10px;
  padding: 2em;
  overflow-x: auto;
  /* Preserve line breaks and wrap long lines */
  /* white-space: pre-wrap; */
  /* Break long words */
  /* word-break: break-word; */
}

pre code {
  font-family: monospace;
  font-size: inherit;
  /* Use the same font size as its parent */
  color: #eee;
  padding: 2rem;
  background: #e5f4ff00;
}

code {
  background: #e5f4ff;
  color: #161B22;
  font-weight: bold;
  border-radius: 5px;
  padding: 1px 10px !important;
  overflow-wrap: break-word;
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
  }
}